import { useState } from "react";
import { useTranslation } from "react-i18next";
import Promocode from "./common/Promocode";
import PaymentForm from "./PaymentForm";
import PaymentButton from "./common/PaymentButton";
import { usePaymentLogic } from "./hooks/usePaymentLogic";
import { skinDeposit } from "../../api/deposit/skinDeposit";
import { useNotification } from "../../context/NotificationContext";
import { DepositMethod } from "./types";
import { Img } from "react-image";
import { useUserStore } from "../../zustand/stores/userStore";

// Определение пропсов для компонента Pay
interface PayProps {
  activeButton: string;
  selectedPayItem: DepositMethod | null;
  activePromocode?: {
    promocode: string;
    procent_deposit: number;
  };
}

const Pay: React.FC<PayProps> = ({
  activeButton,
  selectedPayItem,
  activePromocode,
}) => {
  // Получение функции для добавления уведомлений
  const { addNotification } = useNotification();
  // Хук для интернационализации
  const { t } = useTranslation();
  // Состояние для отслеживания загрузки при покупке скинов
  const [IsSkinLoading, setIsSkinLoading] = useState<boolean>(false);
  // Получение символа валюты пользователя
  const symbol = useUserStore.use.getSymbol()();
  // Проверка, совпадает ли валюта выбранного метода оплаты с валютой пользователя
  const isSameCurrency = selectedPayItem?.currency_symbol === symbol;
  // Проверка, конвертируется ли рубль в доллар
  const isRubToUsd = selectedPayItem?.currency === "RUB" && symbol === "$";

  // Использование хука usePaymentLogic для управления логикой оплаты
  const {
    depositAmount,
    getUsdAmount,
    giftCode,
    phoneNumber,
    handleDepositChange,
    handleGetUsdChange,
    handleGiftCodeChange,
    handlePhoneNumberChange,
    isGiftCardButtonActive,
    isWalletsButtonActive,
    isDepositBelowMin,
    isUsdBelowMin,
  } = usePaymentLogic(
    selectedPayItem?.min ?? 0,
    Number(selectedPayItem?.rate) || 1,
    isSameCurrency,
    isRubToUsd
  );

  // Обработчик для покупки скинов
  const handleBuySkinClick = async () => {
    try {
      setIsSkinLoading(true);
      const depositLink = await skinDeposit();
      window.open(depositLink, "_blank");
    } catch (error: unknown) {
      // Обработка ошибок при получении ссылки для депозита скинов
      if (error instanceof Error) {
        console.error("Error receiving link when depositing skin:", error);
        const errorMessage = error.message || "An unexpected error occurred.";
        addNotification(errorMessage, "error");
      } else {
        console.error("Error receiving link when depositing skin -2:", error);
        addNotification("Error receiving link when depositing skin.", "error");
      }
    } finally {
      setIsSkinLoading(false);
    }
  };

  // Имитация обработчиков для покупки карт, использования кошельков и криптовалют
  const handleBuyCardsClick = async () => {
    setIsSkinLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Имитация асинхронной операции
    console.log("Simulating Cards purchase");
    addNotification("Simulated Cards purchase", "success");
    setIsSkinLoading(false);
  };

  const handleBuyWalletsClick = async () => {
    setIsSkinLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Имитация асинхронной операции
    console.log("Simulating Wallets purchase");
    addNotification("Simulated Wallets purchase", "success");
    setIsSkinLoading(false);
  };

  const handleBuyCryptoClick = async () => {
    setIsSkinLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Имитация асинхронной операции
    console.log("Simulating Crypto purchase");
    addNotification("Simulated Crypto purchase", "success");
    setIsSkinLoading(false);
  };

  // Функция для получения соответствующего обработчика клика в зависимости от активной кнопки
  const getClickHandler = () => {
    switch (activeButton) {
      case "Skins":
        return handleBuySkinClick;
      case "Cards":
        return handleBuyCardsClick;
      case "Wallets":
        return handleBuyWalletsClick;
      case "Crypto":
        return handleBuyCryptoClick;
      default:
        return () => console.log("No action for this button");
    }
  };

  // Функция для отображения информации о выбранном методе оплаты
  const renderSelectedCardInfo = () => {
    if (
      !selectedPayItem ||
      !["Cards", "Wallets", "Crypto", "Skins"].includes(
        selectedPayItem.wallet_type
      )
    ) {
      return null;
    }

    return (
      <div className="w-full bg-dark4 p-4 rounded-lg mt-2.5 md:order-2">
        <div className="flex items-center">
          <Img
            src={selectedPayItem.icon}
            alt={selectedPayItem.name}
            className="w-20 h-12 mr-4 object-contain"
          />
          <div>
            <p className="text-gray-400 text-sm">{t("selected-method")}</p>
            <h3 className="text-white font-semibold">{selectedPayItem.name}</h3>
          </div>
        </div>
      </div>
    );
  };

  // Определение активности кнопки оплаты
  const isPaymentButtonActive =
    activeButton === "Gift_card"
      ? isGiftCardButtonActive
      : selectedPayItem !== null;

  // Рендеринг компонента
  return (
    <div className="sticky top-5 self-start flex flex-col items-center md:bg-dark3 md:p-6 rounded-20p">
      <Promocode activePromocode={activePromocode} />
      {renderSelectedCardInfo()}
      {activeButton === "Skins" ? (
        // Рендеринг кнопки оплаты для скинов
        <PaymentButton
          activeButton={activeButton}
          onClick={handleBuySkinClick}
          isActive={isPaymentButtonActive}
          isSkinLoading={IsSkinLoading}
          isDepositBelowMin={isDepositBelowMin}
          isUsdBelowMin={isUsdBelowMin}
        />
      ) : activeButton === "Crypto" ? (
        // Рендеринг формы оплаты для криптовалют
        <PaymentForm
          activeButton={activeButton}
          depositAmount={depositAmount}
          getUsdAmount={getUsdAmount}
          giftCode={giftCode}
          phoneNumber={phoneNumber}
          handleDepositChange={handleDepositChange}
          handleGetUsdChange={handleGetUsdChange}
          handleGiftCodeChange={handleGiftCodeChange}
          handlePhoneNumberChange={handlePhoneNumberChange}
          selectedPayItem={selectedPayItem}
          isSameCurrency={isSameCurrency}
          isDepositBelowMin={isDepositBelowMin}
          isUsdBelowMin={isUsdBelowMin}
        />
      ) : (
        // Рендеринг формы оплаты и кнопки для других методов оплаты
        <>
          <PaymentForm
            activeButton={activeButton}
            depositAmount={depositAmount}
            getUsdAmount={getUsdAmount}
            giftCode={giftCode}
            phoneNumber={phoneNumber}
            handleDepositChange={handleDepositChange}
            handleGetUsdChange={handleGetUsdChange}
            handleGiftCodeChange={handleGiftCodeChange}
            handlePhoneNumberChange={handlePhoneNumberChange}
            selectedPayItem={selectedPayItem}
            isSameCurrency={isSameCurrency}
            isDepositBelowMin={isDepositBelowMin}
            isUsdBelowMin={isUsdBelowMin}
          />
          <PaymentButton
            activeButton={activeButton}
            isGiftCardButtonActive={isGiftCardButtonActive}
            isWalletsButtonActive={isWalletsButtonActive}
            onClick={getClickHandler()}
            isActive={isPaymentButtonActive}
            isSkinLoading={IsSkinLoading}
            isDepositBelowMin={isDepositBelowMin}
            isUsdBelowMin={isUsdBelowMin}
          />
        </>
      )}
    </div>
  );
};

export default Pay;
