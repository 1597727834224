import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CaseImg from "./CaseImg";
import SliderGorizontal from "./SliderGorizontal";
import { SkinTypeSlider, SkinWine } from "../../../utils/types";
import { getOpenCase } from "../../../api/cases/apiGetOpenCase";
import { useNotification } from "../../../context/NotificationContext";
import { Img } from "react-image";
import MultiSliderBlock from "./MultiSliderBlock";
import { useUserStore } from "../../../zustand/stores/userStore";
import DualButton from "./DualButton";
import { sellSkin } from "../../../api/skins/apiSellSkin";

interface BloksProps {
  skins: SkinTypeSlider[];
  name: string;
  caseImg: string;
  priceCase: number;
}

export default function SliderBlock({
  skins,
  name,
  priceCase,
  caseImg,
}: BloksProps) {
  const { addNotification } = useNotification();
  const [activeButton, setActiveButton] = useState<number>(1);
  const [showSliderBlock, setShowSliderBlock] = useState<boolean>(false);
  const [initialSpin, setInitialSpin] = useState<string[] | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const updateBalance = useUserStore((state) => state.updateBalance);
  const symbol = useUserStore.use.getSymbol()();
  const [totalWonPrice, setTotalWonPrice] = useState<number | null>(null);
  const { t } = useTranslation();
  const [isFastMode, setIsFastMode] = useState(false);
  const [wonSkinIds, setWonSkinIds] = useState<number[]>([]);
  const [wonSkinPrices, setWonSkinPrices] = useState<string[]>([]); // Новое состояние для хранения цен выигранных скинов

  // Преобразование строки пришедшей с бека в число (для totalWonPrice - общая сумма продажи скинов в слайдере)
  const parseStringToNumber = (value: string | number): number => {
    if (typeof value === "number") return value;
    // Удаляем все нечисловые символы, кроме точки и минуса
    const cleanedString = value.replace(/[^\d.-]/g, "");
    const number = parseFloat(cleanedString);
    return isNaN(number) ? 0 : number;
  };

  const handleOpenForClick = async () => {
    if (!showSliderBlock) {
      try {
        setLoading(true);
        setIsSpinning(true);
        const caseData: SkinWine = await getOpenCase(name, activeButton); // Отправка названия кейса и количество слайдеров (для получения в ответ названий скинов (для передачи в слайдеры) и их id (для кнопок продажи))
        // Извлекаем названия скинов для передачи в слайдеры
        const skinNamesList = caseData.data.skins.map((skin) => skin.skin);

        // Извлекаем ID записи из user_skins для кнопок продажи
        const skinIdsList = caseData.data.skins.map((skin) => skin.id);
        // Извлекаем цену победных скинов user_skins для победных карточек
        const skinPriceWin = caseData.data.skins.map((skin) => skin.price);

        setInitialSpin(skinNamesList); // Сохраняем названия выигранных скинов для передачи их в слайдер
        setTotalWonPrice(parseStringToNumber(caseData.data.total_won_price));
        setWonSkinIds(skinIdsList); // Сохраняем ID выигранных скинов для кнопок продажи
        setWonSkinPrices(skinPriceWin); // Сохраняем цены выигранных скинов

        setShowSliderBlock(true);

        updateBalance(caseData.data.balance);
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error("Failed to open case:", error);
          const errorMessage =
            error.message || "An unexpected error occurred -1";
          addNotification(errorMessage, "error");
        } else {
          console.error("Failed to open case -2:", error);
        }
        setShowSliderBlock(false);
        setIsSpinning(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSellSkins = async () => {
    try {
      setLoading(true);
      const response = await sellSkin(wonSkinIds); // Срабатывает после нажатия общей кнопке продажи (у горизонтального и у вертикального слайдера)
      if (response.success) {
        updateBalance(response.new_balance);
        handleTryAgain(); // Сбрасываем состояние рулетки
      }
    } catch (error) {
      let errorMessage = "";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      console.error("Error selling skins:", error);
      addNotification(errorMessage, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSpinEnd = useCallback(() => {
    setIsSpinning(false);
  }, []);

  const handleFastModeChange = (isfast: boolean) => {
    setIsFastMode(isfast);
  };

  const renderBlock = () => {
    if (showSliderBlock && initialSpin !== undefined) {
      if (activeButton === 1) {
        return (
          <SliderGorizontal
            skins={skins}
            initialSpin={initialSpin[0]}
            onSpinEnd={handleSpinEnd}
            isFastMode={isFastMode}
          />
        );
      } else if (activeButton >= 2 && activeButton <= 5) {
        return (
          <MultiSliderBlock
            count={activeButton}
            skins={skins}
            initialSpins={initialSpin}
            wonSkinPrices={wonSkinPrices}
            onSpinEnd={handleSpinEnd}
            isFastMode={isFastMode}
            wonSkinIds={wonSkinIds}
            handleTryAgain={handleTryAgain} // Для сброса в начальное состояние сладйдера после продажи все выигрышных скинов по одному
          />
        );
      }
    }

    return <CaseImg count={activeButton} caseImg={caseImg} />;
  };

  const handleTryAgain = () => {
    setShowSliderBlock(false);
    setInitialSpin(undefined);
    setIsSpinning(false);
    // setActiveButton(1); Убран сброс состояния кнопок, чтобы при возврате выбор сохранялся
  };

  const renderSliderButtons = () => (
    <div className="flex flex-wrap justify-between items-center gap-1.5 md:gap-4 mt-9">
      <button
        className="flex-1 sm:flex-initial order-1 sm:order-1 flex justify-center md:justify-between items-center px-3 md:px-4 py-3 md:py-c13 bg-grey4 hover:bg-grey4 text-dark8 text-13 md:text-14.24 font-bold rounded-30p"
        onClick={handleTryAgain}
      >
        <div className="inline-block w-5 h-5 sm:w-c26 sm:h-c26 mr-1.5">
          <Img
            src="/images/cases/try.svg"
            alt="try again"
            className="object-cover w-full h-full"
            loader={<span className="inline-block w-full h-full" />}
          />
        </div>
        {t("try-again")}
      </button>
      <button
        className="order-3 sm:order-2 w-full sm:w-auto flex justify-center md:justify-between items-center px-3 md:px-4 py-3 md:py-c13 bg-green3 hover:bg-green4 text-dark8 text-13 md:text-14.24 font-bold rounded-30p"
        onClick={handleSellSkins}
        disabled={loading || !initialSpin}
      >
        <div className="inline-block w-5 h-5 sm:w-c26 sm:h-c26 mr-1.5">
          {loading ? (
            <div className="w-full h-full border-t-2 border-b-2 border-dark8 rounded-full animate-spin"></div>
          ) : (
            <Img
              src="/images/profile/cart.svg"
              alt="sell"
              className="object-cover w-full h-full"
              loader={<span className="inline-block w-full h-full" />}
            />
          )}
        </div>
        {t("sell-for")} {symbol}
        {totalWonPrice !== null ? totalWonPrice.toFixed(2) : ""}
      </button>
      {/* <button className="flex-1 sm:flex-initial order-2 sm:order-3 flex justify-center md:justify-between items-center px-3 md:px-4 py-c11 md:py-c13 border border-dark5 text-grey4 text-13 md:text-14.24 font-bold rounded-30p">
        <div className="inline-block w-5 h-5 sm:w-c26 sm:h-c26 mr-1.5">
          <Img
            src="/images/panel-header/contracts-white.svg"
            alt="add contract"
            className="object-cover w-full h-full"
            loader={<span className="inline-block w-full h-full" />}
          />
        </div>
        {t("add-contract")}
      </button> */}
    </div>
  );

  return (
    <>
      {renderBlock()}
      <div className="flex justify-center items-center gap-2">
        {isSpinning ? (
          <div className="flex flex-col justify-center items-center mt-9">
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
            <p className="text-white text-sm mt-2">{t("case-opening")}...</p>
          </div>
        ) : showSliderBlock ? (
          renderSliderButtons()
        ) : (
          <>
            <div className="flex flex-wrap items-center justify-center gap-1 sm:gap-4">
              <div className="order-1 sm:order-1 flex gap-x-0.5 p-c5 border border-dark6 rounded-full text-10 sm:text-14.24 font-bold overflow-x-auto ">
                {[1, 2, 3, 4, 5].map((index) => (
                  <button
                    key={index}
                    className={`flex-shrink-0 w-c34 h-c34 sm:w-c52 sm:h-c50 px-0 py-2 sm:px-5 sm:py-c13 bg-dark5 rounded-30p krug ${
                      activeButton === index
                        ? "bg-grey4 text-dark1"
                        : "bg-dark1 text-grey4"
                    }`}
                    onClick={() => setActiveButton(index)}
                  >
                    x{index}
                  </button>
                ))}
              </div>

              <button
                className="w-full sm:w-auto order-3 sm:order-2 sm:flex-grow-0 flex justify-center sm:justify-between items-center max-h-c30 px-c34 py-4 bg-red4 hover:bg-red5 text-dark3 text-13.20 sm:text-15.28 font-bold rounded-30p"
                onClick={handleOpenForClick}
              >
                {t("open-for")} {symbol}
                {(priceCase * activeButton).toFixed(2)}
              </button>

              <div className="order-2 sm:order-3 flex-grow-0">
                <DualButton
                  onModeChange={handleFastModeChange}
                  isFastMode={isFastMode}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
