import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";

interface SoundContextType {
  isSoundOn: boolean;
  volume: number;
  toggleSound: () => void;
  playClickSound: () => void;
  playWinSound: () => void;
  setVolume: (volume: number) => void;
}

const SoundContext = createContext<SoundContextType>({
  isSoundOn: true,
  volume: 1,
  toggleSound: () => {},
  playClickSound: () => {},
  playWinSound: () => {},
  setVolume: () => {},
});

export const useSound = () => useContext(SoundContext);

export const SoundProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isSoundOn, setIsSoundOn] = useState(() => {
    const saved = localStorage.getItem("isSoundOn");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [volume, setVolumeState] = useState(() => {
    const saved = localStorage.getItem("volume");
    return saved !== null ? JSON.parse(saved) : 1;
  });
  const [userInteracted, setUserInteracted] = useState(false);
  const clickSoundRef = useRef<HTMLAudioElement | null>(null);
  const winSoundRef = useRef<HTMLAudioElement | null>(null);

  const updateAllVolumes = useCallback((newVolume: number) => {
    if (clickSoundRef.current) clickSoundRef.current.volume = newVolume;
    if (winSoundRef.current) winSoundRef.current.volume = newVolume;
  }, []);

  useEffect(() => {
    clickSoundRef.current = new Audio("/sounds/click.mp3");
    winSoundRef.current = new Audio("/sounds/win.mp3");
    updateAllVolumes(volume);
  }, []);

  useEffect(() => {
    updateAllVolumes(volume);
    localStorage.setItem("volume", JSON.stringify(volume));
  }, [volume, updateAllVolumes]);

  useEffect(() => {
    localStorage.setItem("isSoundOn", JSON.stringify(isSoundOn));
  }, [isSoundOn]);

  const setVolume = useCallback((newVolume: number) => {
    setVolumeState(newVolume);
  }, []);

  const toggleSound = useCallback(() => {
    setIsSoundOn((prevState: boolean) => !prevState);
  }, []);

  const playClickSound = useCallback(() => {
    if (isSoundOn && clickSoundRef.current && userInteracted) {
      clickSoundRef.current.currentTime = 0;
      clickSoundRef.current.play();
    }
  }, [isSoundOn, userInteracted]);

  const playWinSound = useCallback(() => {
    if (isSoundOn && winSoundRef.current && userInteracted) {
      winSoundRef.current.currentTime = 0;
      winSoundRef.current.play();
    }
  }, [isSoundOn, userInteracted]);

  useEffect(() => {
    const handleUserInteraction = () => {
      setUserInteracted(true);
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
    };

    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("keydown", handleUserInteraction);

    return () => {
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
    };
  }, []);

  const contextValue = React.useMemo(
    () => ({
      isSoundOn,
      volume,
      toggleSound,
      playClickSound,
      playWinSound,
      setVolume,
    }),
    [isSoundOn, volume, toggleSound, playClickSound, playWinSound, setVolume]
  );

  return (
    <SoundContext.Provider value={contextValue}>
      {children}
    </SoundContext.Provider>
  );
};
