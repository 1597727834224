import { Img } from "react-image";
import { useTranslation } from "react-i18next";
import CaseImg from "./CaseImg";
import { Link } from "react-router-dom";
import { useUserStore } from "../../../zustand/stores/userStore";

interface BlokBalanceProps {
  caseImg: string;
  missingBalance: number;
}

export default function BlokBalance({
  caseImg,
  missingBalance,
}: BlokBalanceProps) {
  const { t } = useTranslation();
  const symbol = useUserStore.use.getSymbol()();
  console.log("после вычитания:", missingBalance);
  return (
    <>
      <CaseImg count={1} caseImg={caseImg} />
      <div className="flex flex-col sm:flex-row justify-center items-center gap-2">
        <div className="w-full sm:w-auto flex justify-center sm:justify-between items-center gap-c7 py-3 sm:py-c21 px-8 border border-solid border-yellow1 text-yellow4 text-13 sm:text-15 font-bold rounded-30p">
          {t("not-enough")} {symbol}
          {missingBalance} {t("on-balance")}
        </div>
        <Link to="/deposit" className="w-full sm:w-auto">
          <button className="w-full sm:w-auto flex justify-center sm:justify-between items-center py-3 sm:py-4 px-c34 ml-0 sm:ml-5 mr-2.5 bg-yellow4 hover:bg-yellow6 text-dark3 text-13 sm:text15.28 font-bold rounded-30p">
            {t("refill-balance")}
            <div className="inline-block w-5 h-5 sm:w-7 sm:h-7 ml-c7">
              <Img
                src="/images/panel-header/plus.svg"
                alt="login-steam"
                className="object-cover w-full h-full"
                loader={<span className="inline-block w-full h-full ml-c7" />}
              />
            </div>
          </button>
        </Link>
      </div>
    </>
  );
}
