import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createSelectors } from "../utils/createSelectors";
import { SkinType } from "../../utils/types";

type SkinStatus =
  | "DEFAULT"
  | "SOLD"
  | "EXCHANGE"
  | "WITHDRAW"
  | "WITHDRAW_PENDING"
  | "WITHDRAW_SENT";
type WithdrawStatus = "WITHDRAW" | "WITHDRAW_PENDING" | "WITHDRAW_SENT";

interface ProfileStore {
  skins: SkinType[]; // Скины полученные с бекенда на странице личного профиля
  loading: boolean;
  hasMore: boolean;
  page: number;
  isDefault: boolean;
  recentlySoldSkins: { [key: number]: SkinStatus } & { all?: "SOLD" }; // Для обновления статуса скинов при продаже и обмене
  allSkinsSold: boolean; // Сброс цены в кнопке при продаже всех скинов в профиле
  totalPrice: string; // Общая стоимость всех доступных к продаже скинов в профиле
  availableCount: number | null; // Количество доступных к продаже скинов в профиле
  skinInfo: SkinType | null; // Информация о скине передаваемая в модальное окно продажи скина и модальное окно замены скина
  skin_count: number; // Количество всех скинов или доступных к продаже(со статусом DEFAULT) в зависимости от чекбокса(Доступно мне)
  setSkins: (newSkins: SkinType[], append?: boolean) => void;
  setLoading: (loading: boolean) => void;
  setHasMore: (hasMore: boolean) => void;
  setPage: (page: number) => void;
  setIsDefault: (isDefault: boolean) => void;
  addSoldSkin: (skinId: number | number[] | "all") => void;
  addExchangedSkin: (skinId: number) => void;
  setAllSkinsSold: (sold: boolean) => void;
  addWithdrawnSkin: (skinId: number, status: WithdrawStatus) => void;
  setTotalPrice: (price: string) => void;
  setAvailableCount: (count: number | null) => void;
  setSkinInfo: (skinInfo: SkinType | null) => void;
  reset: () => void;
  setSkinCount: (count: number) => void;
  resetRecentlySoldSkins: () => void;
}

export const useProfileStore = createSelectors(
  create<ProfileStore>()(
    immer(
      devtools(
        (set) => ({
          skins: [], // Инициализируем как пустой массив
          loading: false,
          hasMore: true,
          page: 1,
          isDefault: true,
          recentlySoldSkins: {},
          allSkinsSold: false,
          totalPrice: "0.00",
          availableCount: null,
          skinInfo: null,
          skin_count: 0,
          setSkins: (newSkins, append = false) =>
            set((state) => {
              if (append) {
                state.skins = [...state.skins, ...newSkins];
              } else {
                state.skins = newSkins;
              }
            }),
          setLoading: (loading) => set({ loading }),
          setHasMore: (hasMore) => set({ hasMore }),
          setPage: (page) => set({ page }),
          setIsDefault: (isDefault) => set({ isDefault }),
          addSoldSkin: (skinId) =>
            set((state) => {
              const newSoldSkins = { ...state.recentlySoldSkins };

              if (skinId === "all") {
                state.skins.forEach((skin) => {
                  if (skin.status === "DEFAULT") {
                    newSoldSkins[skin.id] = "SOLD";
                  }
                });
                newSoldSkins.all = "SOLD";
                state.allSkinsSold = true;
                state.totalPrice = "0.00";
                state.availableCount = 0;
              } else if (typeof skinId === "number") {
                const skin = state.skins.find((s) => s.id === skinId);
                if (skin && skin.status === "DEFAULT") {
                  newSoldSkins[skinId] = "SOLD";
                }
              } else if (Array.isArray(skinId)) {
                skinId.forEach((id) => {
                  const skin = state.skins.find((s) => s.id === id);
                  if (skin && skin.status === "DEFAULT") {
                    newSoldSkins[id] = "SOLD";
                  }
                });
              }

              state.recentlySoldSkins = newSoldSkins;
            }),
          setAllSkinsSold: (sold) => set({ allSkinsSold: sold }),
          setTotalPrice: (price) => set({ totalPrice: price }),
          setAvailableCount: (count) => set({ availableCount: count }),
          setSkinInfo: (skinInfo) => set({ skinInfo }),
          reset: () =>
            set({
              skins: [],
              loading: false,
              hasMore: true,
              page: 1,
              isDefault: true,
              recentlySoldSkins: {},
              allSkinsSold: false,
              totalPrice: "0.00",
              availableCount: null,
              skinInfo: null,
            }),
          setSkinCount: (count) =>
            set((state) => {
              state.skin_count = count;
            }),
          addExchangedSkin: (skinId) =>
            set((state) => {
              const newExchangedSkins = { ...state.recentlySoldSkins };
              const skin = state.skins.find((s) => s.id === skinId);
              if (skin && skin.status === "DEFAULT") {
                newExchangedSkins[skinId] = "EXCHANGE";
              }
              state.recentlySoldSkins = newExchangedSkins;
            }),
          resetRecentlySoldSkins: () => set({ recentlySoldSkins: {} }),
          addWithdrawnSkin: (skinId, status) =>
            set((state) => {
              const newWithdrawnSkins = { ...state.recentlySoldSkins };
              const skin = state.skins.find((s) => s.id === skinId);
              if (
                skin &&
                (skin.status === "DEFAULT" ||
                  skin.status.startsWith("WITHDRAW"))
              ) {
                newWithdrawnSkins[skinId] = status;
              }
              state.recentlySoldSkins = newWithdrawnSkins;
            }),
        }),

        {
          enabled: true,
          name: "Profile store",
        }
      )
    )
  )
);
