import { useState, useEffect } from "react";
import { Range, getTrackBackground } from "react-range";

interface RangeSliderProps {
  onChange: (values: [number, number]) => void;
  resetRange: boolean;
  maxPrice: number;
}

const RangeSlider = ({ onChange, resetRange, maxPrice }: RangeSliderProps) => {
  const [values, setValues] = useState<[number, number]>([0, maxPrice]);

  const colors = ["#282E44", "#ffc85f", "#282E44"];

  useEffect(() => {
    if (resetRange) {
      setValues([0, maxPrice]);
      onChange([0, maxPrice]);
    }
  }, [resetRange, maxPrice, onChange]);

  useEffect(() => {
    setValues((prev) => [prev[0], maxPrice]);
  }, [maxPrice]);

  useEffect(() => {
    console.log("RangeSlider values:", values);
    console.log("maxPrice:", maxPrice);
  }, [values, maxPrice]);

  return (
    <section className="flex flex-col gap-2 w-full h-full max-w-md items-start justify-center sm:w-full md:w-c242 md:mb-1">
      <span className="w-full flex justify-between text-12.18 font-semibold text-grey5 mb-1">
        <div>${values[0]}</div>
        <div>${values[1]}</div>
      </span>
      <div className="relative w-full">
        <Range
          values={values}
          step={1}
          min={0}
          max={maxPrice}
          onChange={(newValues) => {
            const newRange: [number, number] = [newValues[0], newValues[1]];
            setValues(newRange);
            onChange(newRange);
            console.log("New range set:", newRange);
          }}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              className="h-1.5 rounded-xl"
              style={{
                ...props.style,
                background: getTrackBackground({
                  values: values,
                  colors: colors,
                  min: 0,
                  max: maxPrice,
                }),
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              key={props.key}
              className="w-2.5 h-2.5 rounded-full bg-cwhite cursor-pointer focus:outline-none"
              style={{ ...props.style }}
            />
          )}
        />
      </div>
    </section>
  );
};

export default RangeSlider;
