import { useEffect } from "react";
import { useUserStore } from "../../zustand/stores/userStore";
import { getUserData } from "./apiGetUserData";

const useFetchUserData = () => {
  const setUserData = useUserStore.use.setUserData();
  const reset = useUserStore.use.reset();
  const setNeedsRedirect = useUserStore.use.setNeedsRedirect();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserData();
        setUserData(userData);
        console.log(userData);
      } catch (error: unknown) {
        if (error instanceof Error && error.message === "Unauthorized") {
          // Если ошибка связана с истечением срока действия токена
          reset();
          // Устанавливаем флаг необходимости редиректа
          setNeedsRedirect(true);
        } else {
          reset();
        }
      }
    };

    fetchUserData();
  }, [setUserData, reset, setNeedsRedirect]);
};

export default useFetchUserData;
