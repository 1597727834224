import React, { useState } from "react";
import { Img } from "react-image";
import { useTranslation } from "react-i18next";
import { useModalsStore } from "../../zustand/stores/modalsStore";
import { useProfileStore } from "../../zustand/stores/profileStore";
import { SkinType } from "../../utils/types";
import { ModalWrapper } from "./utils/modalWrapper";
import { CardModalReplace } from "../profile/CardModalReplace";
import { useUserStore } from "../../zustand/stores/userStore";
import { useNotification } from "../../context/NotificationContext";
import { exchangeSkin } from "../../api/profile/exchangeSkin";
import truncateName from "../../utils/truncateName";

interface ReplaceSkinModalProps {
  exchangeableSkins: SkinType[];
}

const ReplaceSkinModal: React.FC<ReplaceSkinModalProps> = ({
  exchangeableSkins,
}) => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const [isExchanging, setIsExchanging] = useState(false);
  const [lastClickedId, setLastClickedId] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState<SkinType | null>(null);
  const [priceDifference, setPriceDifference] = useState<number | null>(null);
  const symbol = useUserStore.use.getSymbol()();

  const isReplaceSkinModalOpen = useModalsStore(
    (state) => state.isReplaceSkinModalOpen
  );
  const setIsReplaceSkinModalOpen = useModalsStore(
    (state) => state.setIsReplaceSkinModalOpen
  );

  const ReplaceSkinInfo = useProfileStore((state) => state.skinInfo);
  const addExchangedSkin = useProfileStore((state) => state.addExchangedSkin);
  const updateBalance = useUserStore((state) => state.updateBalance);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => setIsReplaceSkinModalOpen(false);

  const calculatePriceDifference = (selectedPrice: number) => {
    if (ReplaceSkinInfo?.price) {
      return ReplaceSkinInfo.price - selectedPrice;
    }
    return null;
  };

  const handleCardClick = (item: SkinType) => {
    if (item.id === lastClickedId) {
      setLastClickedId(null);
      setSelectedItem(null);
      setPriceDifference(null);
    } else {
      setLastClickedId(item.id);
      setSelectedItem(item);
      setPriceDifference(calculatePriceDifference(item.price));
    }
  };

  const handleReplaceSkin = async () => {
    if (!selectedItem || !ReplaceSkinInfo) {
      addNotification(t("Please select a skin to exchange"), "error");
      return;
    }

    setIsExchanging(true);
    try {
      const result = await exchangeSkin(selectedItem.id, ReplaceSkinInfo.id);

      // Обновляем статус обмененного скина в recentlySoldSkins
      addExchangedSkin(ReplaceSkinInfo.id);

      // Обновляем баланс
      updateBalance(result.balance);

      // Добавляем новый скин в начало списка
      const updateSkins = useProfileStore.getState().setSkins;
      const currentSkins = useProfileStore.getState().skins;
      updateSkins([result.new_skin, ...currentSkins]);

      addNotification(t("Skin successfully exchanged"), "success");
      setIsReplaceSkinModalOpen(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Error exchanging skin:", error);
        const errorMessage = error.message || "An unexpected error occurred.";
        setError(errorMessage || "An unexpected error occurred");
        addNotification(errorMessage, "error");
      } else {
        console.error("Error exchanging skin -2:", error);
      }
      setTimeout(() => {
        setIsReplaceSkinModalOpen(false);
      }, 2000);
    } finally {
      setIsExchanging(false);
    }
  };

  return (
    <ModalWrapper
      isOpen={isReplaceSkinModalOpen}
      onClose={() => setIsReplaceSkinModalOpen(false)}
      className="flex flex-col text-center relative bg-dark4 p-8 rounded-3xl px-c14 pt-c14 pb-0 md:p-8 md:px-c34 md:pt-8 md:pb-8 mx-2.5 w-full sm:w-auto"
    >
      <div className="relative sm:static max-w-[568px]">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-1.5">
            <Img
              src="/images/profile/rep-icon.svg"
              alt="exchange"
              width={30}
              height={30}
              loader={
                <span
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              }
            />
            <div className="flex flex-col items-center md:flex-row gap-0.5 md:gap-1.5 text-18 text=white font-semibold">
              {t("skin-replacement")}{" "}
              <span className="p-2 bg-replace text-12 rounded-md">
                {t("to-the-balance")}
                {priceDifference !== null && (
                  <span className="ml-2 text-yellow4">
                    {priceDifference === 0
                      ? "0.00"
                      : priceDifference.toFixed(2)}{" "}
                    {symbol}
                  </span>
                )}
              </span>
            </div>
          </div>
          <button className="text-gray-500" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 20 20"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex gap-6 justify-evenly items-center bg-replace rounded-20p mt-4 py-4 px-2">
          <div className="w-c229 flex flex-col items-end">
            <div className="flex flex-col sm:flex-row text-13 text-grey3 font-medium">
              {truncateName(ReplaceSkinInfo?.weapon, 9)} |&nbsp;
              <span className="text-13 text-white">
                {truncateName(ReplaceSkinInfo?.skin_name, 15)}
              </span>
            </div>
            <p className="text-13 text-yellow4 font-semibold">
              {symbol}
              {ReplaceSkinInfo?.price}
            </p>
          </div>
          <div className="min-w-7 min-h-7 sm:w-c38 sm:h-c38">
            <Img
              src="/images/profile/replace.svg"
              alt="exchange"
              className="object-contain w-full h-full"
              loader={<span className="w-full h-full" />}
            />
          </div>
          <div className="w-c229 flex flex-col items-start">
            {selectedItem ? (
              <>
                <div className="flex flex-col sm:flex-row text-13 text-grey3 font-medium">
                  {truncateName(selectedItem.weapon, 9)} |&nbsp;
                  <span className="text-13 text-white">
                    {truncateName(selectedItem.skin_name, 15)}
                  </span>
                </div>
                <p className="text-13 text-yellow4 font-semibold">
                  {symbol}
                  {selectedItem.price}
                </p>
              </>
            ) : (
              <>
                <p className="text-15 text-grey3 font-medium">
                  {t("select-item")}
                </p>
              </>
            )}
          </div>
        </div>
        <div className="relative gradient-overlay w-full sm:w-auto">
          <div className="max-h-[499px] sm:max-h-[552px] grid grid-cols-3 sm:grid-cols-4 gap-1.5 sm:gap-[5px] pb-24 mt-4 overflow-y-auto custom-scrollbar pr-1.5">
            {exchangeableSkins.map((item) => (
              <CardModalReplace
                key={item.id}
                casesItem={item}
                isClicked={item.id === lastClickedId}
                onClick={() => handleCardClick(item)}
              />
            ))}
          </div>
        </div>

        <div className="absolute w-full sm:w-auto flex gap-2 bottom-6 sm:bottom-7 z-20">
          <button
            className="flex justify-between items-center px-c18 py-2 sm:px-9 sm:py-c17 text-grey4 text-12.18 sm:text-15.26 font-bold bg-dark4 border border-dark5 rounded-30p"
            onClick={handleClose}
          >
            {t("cancel")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-c18 h-c18 sm:h-c26 sm:w-c26 ml-2"
              fill="none"
              viewBox="0 0 26 26"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <button
            className={`w-full flex justify-center items-center p-2 sm:px-c125.5 sm:py-c17 bg-yellow4 hover:bg-yellow6 text-dark8 text-12.18 sm:text-15.26 font-bold rounded-30p transition-opacity duration-300 ${
              !selectedItem
                ? "opacity-50 cursor-not-allowed"
                : "hover:opacity-100"
            }`}
            onClick={handleReplaceSkin}
            disabled={isExchanging || !selectedItem}
          >
            {t("replace")}
            <div className="w-c18 h-c18 sm:w-c26 sm:h-c26 ml-2">
              {isExchanging ? (
                <div className="w-full h-full border-t-2 border-b-2 border-dark8 rounded-full animate-spin"></div>
              ) : (
                <Img
                  src="/images/profile/button-icon.svg"
                  alt="cart"
                  className="object-contain w-full h-full"
                  loader={<span className="w-full h-full" />}
                />
              )}
            </div>
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ReplaceSkinModal;
