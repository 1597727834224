import "./home.css";
import { useEffect, useState, useMemo, useCallback } from "react";
import { CaseCard } from "../../components/cases/caseCard/CaseCard";
import Filtr from "../../components/filtr/Filtr";
import { Giveaways } from "../../components/giveaways/Giveaways";
import { getCases } from "../../api/cases/apiGetCaseData";
import { useNotification } from "../../context/NotificationContext";
import SkeletonCaseCard from "../../components/cases/caseCard/SkeletonCaseCard";
import { useUserStore } from "../../zustand/stores/userStore";
import { CaseItemType, CasesType, CategoryType } from "../../utils/types";
import { useTranslation } from "react-i18next";

interface Filters {
  searchQuery: string;
  available: boolean;
  priceRange: [number, number];
}

export default function Home() {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const userBalance = useUserStore.use.getBalance()() ?? 0;

  const [maxPrice, setMaxPrice] = useState<number>(1000);

  const [filters, setFilters] = useState<Filters>({
    searchQuery: "",
    available: false,
    priceRange: [0, 1000],
  });

  const updateFilters = useCallback((newFilters: Partial<Filters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const caseData: CasesType = await getCases();
        if (caseData.success && Array.isArray(caseData.data)) {
          setCategories(caseData.data);

          const maxCasePrice = Math.max(
            ...caseData.data.flatMap((category) =>
              category.cases.map((caseItem) => caseItem.price)
            )
          );

          setMaxPrice(maxCasePrice);
          setFilters((prevFilters) => ({
            ...prevFilters,
            priceRange: [prevFilters.priceRange[0], maxCasePrice],
          }));
        } else {
          throw new Error(
            "Не удалось получить данные о кейсах или неверная структура данных"
          );
        }
      } catch (error: unknown) {
        console.error("Ошибка при получении данных о кейсах:", error);
        if (error instanceof Error) {
          addNotification(error.message, "error");
        } else {
          addNotification("Произошла неизвестная ошибка", "error");
        }
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [addNotification]);

  const filteredCategories = useMemo(() => {
    if (loading) return [];
    return categories.map((category) => ({
      ...category,
      cases: category.cases.filter(
        (item: CaseItemType) =>
          item.name.toLowerCase().includes(filters.searchQuery.toLowerCase()) &&
          (!filters.available || item.price <= userBalance) &&
          item.price >= filters.priceRange[0] &&
          item.price <= filters.priceRange[1]
      ),
    }));
  }, [categories, filters, userBalance, loading]);

  const renderContent = () => {
    if (loading) {
      return (
        <>
          <h2 className="text-24 text-white font-bold mt-12 mb-8 text-center">
            {t("loading")}
          </h2>
          <div className="flex items-center justify-center flex-wrap gap-y-5">
            <SkeletonCaseCard />
            <SkeletonCaseCard />
            <SkeletonCaseCard />
          </div>
        </>
      );
    }

    const nonEmptyCategories = filteredCategories.filter(
      (category) => category.cases.length > 0
    );

    if (nonEmptyCategories.length === 0) {
      return (
        <p className="text-white text-center mt-8">{t("no-cases-available")}</p>
      );
    }

    return nonEmptyCategories.map((category) => (
      <div key={category.category}>
        <h2 className="text-24 text-white font-bold mt-12 mb-8 text-center">
          {category.category}
        </h2>
        <div className="flex items-center justify-center flex-wrap gap-y-5">
          {category.cases.map((item: CaseItemType) => (
            <CaseCard key={item.id} cardItem={item} />
          ))}
        </div>
      </div>
    ));
  };

  return (
    <main className="flex flex-col items-center w-full md:max-w-main md:mx-auto pt-c30 pb-c66">
      <div className="w-full flex md:flex-col flex-col-reverse lg:flex-row items-center gap-c15 zoom-filtr">
        <Filtr updateFilters={updateFilters} maxPrice={maxPrice} />
        {/* <Giveaways /> */}
      </div>

      {renderContent()}
    </main>
  );
}
