import React, { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { useNotification } from "../../../context/NotificationContext";
import { useModalsStore } from "../../../zustand/stores/modalsStore";
import { useDepositStore } from "../../../zustand/stores/depositStore";
import { CryptoPayProps, DepositMethodNetwork } from "../types";
import { getCryptoAddress } from "../../../api/deposit/getCryptoAddress";

export default function CryptoPay({
  selectedToken,
  depositAmount,
  minDepositAmount,
}: CryptoPayProps) {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const [showWallet, setShowWallet] = useState(false);
  const [generatedWallet, setGeneratedWallet] = useState("");
  const [selectedNetwork, setSelectedNetwork] =
    useState<DepositMethodNetwork | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const setIsQrCryptoModalOpen = useModalsStore(
    (state) => state.setIsQrCryptoModalOpen
  );
  const setWalletAddress = useDepositStore((state) => state.setWalletAddress);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const checkWalletAddress = (network: DepositMethodNetwork): string | null => {
    if (
      typeof network.wallet === "object" &&
      network.wallet !== null &&
      "address" in network.wallet
    ) {
      return network.wallet.address;
    }
    return null;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      selectedToken.networks &&
      Object.keys(selectedToken.networks).length > 0
    ) {
      const firstNetwork = Object.values(selectedToken.networks)[0];
      setSelectedNetwork(firstNetwork);
      const walletAddress = checkWalletAddress(firstNetwork);
      if (walletAddress) {
        setGeneratedWallet(walletAddress);
        setShowWallet(true);
      } else {
        setShowWallet(false);
        setGeneratedWallet("");
      }
    }
  }, [selectedToken]);

  const handleNetworkSelect = (network: DepositMethodNetwork) => {
    setSelectedNetwork(network);
    const walletAddress = checkWalletAddress(network);
    if (walletAddress) {
      setGeneratedWallet(walletAddress);
      setShowWallet(true);
    } else {
      setShowWallet(false);
      setGeneratedWallet("");
    }
    setIsOpen(false);
  };

  const handleButtonClick = async () => {
    if (!selectedNetwork) {
      return;
    }
    if (depositAmount === null || depositAmount < minDepositAmount) {
      return;
    }
    setIsLoading(true);
    try {
      const params: { network_type: string; token_id?: string } = {
        network_type: selectedNetwork.network_type,
      };
      if (selectedNetwork.network_type === "TON" && selectedNetwork.token_id) {
        params.token_id = selectedNetwork.token_id;
      }
      const response = await getCryptoAddress(params);
      setGeneratedWallet(response.address);
      setShowWallet(true);
    } catch (error) {
      if (error instanceof Error) {
        addNotification(error.message, "error");
      } else {
        addNotification(t("generate-wallet-error"), "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = useCallback(() => {
    navigator.clipboard
      .writeText(generatedWallet)
      .then(() => {
        addNotification(t("wallet-copied"), "success");
      })
      .catch((error) => {
        addNotification(t("copy-error"), "error");
      });
  }, [generatedWallet, addNotification, t]);

  const handleQrClick = () => {
    setWalletAddress(generatedWallet);
    setIsQrCryptoModalOpen(true);
  };

  const isButtonActive =
    selectedNetwork !== null &&
    depositAmount !== null &&
    depositAmount >= minDepositAmount;

  return (
    <div className="w-full flex flex-col items-center gap-y-4">
      <div className="w-full relative" ref={dropdownRef}>
        {/* Network selection dropdown */}
        <p className="text-12 mb-2 ml-3">{t("select-network")}</p>
        <div
          className="w-full flex justify-between items-center px-4 py-2 bg-c-grey2 border border-dotted border-c-grey3 rounded-19p cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="text-white">
            {selectedNetwork
              ? selectedNetwork.network_name
              : t("select-network")}
          </span>
          <svg
            className={`w-4 h-4 text-white transition-transform duration-300 ${
              isOpen ? "rotate-180" : ""
            }`}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
        <div
          className={`absolute w-full mt-1 bg-c-grey3 border border-c-grey3 rounded-19p z-10 transition-all duration-100 z-20 ${
            isOpen ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          {selectedToken.networks &&
            Object.values(selectedToken.networks).map((network) => (
              <div
                key={network.network}
                className="px-4 py-2 hover:bg-c-grey2 cursor-pointer text-white"
                onClick={() => handleNetworkSelect(network)}
              >
                {network.network_name}
              </div>
            ))}
        </div>
      </div>

      {showWallet ? (
        <div className="w-full flex items-center justify-between gap-c9">
          {/* Wallet address display */}
          <div className="w-full flex items-center justify-between px-4 py-c13 bg-yellow7 text-yellow4 text-12.18 font-bold border border-dotted border-yellow4 rounded-30p relative">
            <div className="overflow-x-auto whitespace-nowrap absolute inset-y-0 left-4 right-10 flex items-center scrollbar-hide">
              <span className="min-w-full">{generatedWallet}</span>
            </div>
            <div className="ml-auto pl-2 relative z-10 bg-yellow7">
              <button
                onClick={handleCopy}
                className="inline-block w-5 h-5 md:w-c22 md:h-c22"
                aria-label="copy-wallet-number"
              >
                <Img
                  src={"/images/deposit/copy.svg"}
                  alt="copy"
                  className="object-cover w-full h-full"
                  loader={<span className="inline-block w-full h-full" />}
                />
              </button>
            </div>
          </div>
          {/* QR code button */}
          <div className="bg-dark5 rounded-full w-с50 h-с50 flex items-center justify-center p-c13">
            <button
              className="flex items-center justify-center w-5 h-5 md:w-6 md:h-6"
              onClick={handleQrClick}
            >
              <Img
                src={"/images/deposit/qr.svg"}
                alt="qr-code"
                className="object-contain w-full h-full"
                loader={<span className="inline-block w-full h-full" />}
              />
            </button>
          </div>
        </div>
      ) : (
        <button
          className={`w-full flex justify-center items-center px-4 py-c11 ${
            isButtonActive
              ? "bg-red4 hover:bg-red5 text-dark4"
              : "bg-gray-400 text-gray-600 cursor-not-allowed"
          } text-13.20 md:text-15.28 font-bold rounded-30p`}
          onClick={isButtonActive ? handleButtonClick : undefined}
          disabled={!isButtonActive || isLoading}
        >
          {isLoading ? t("generating-wallet") : t("generate-wallet")}
          <div className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6 ml-1.5">
            <Img
              src="/images/deposit/wallet_icon.svg"
              alt="wallet-icon"
              className="object-contain w-full h-full"
              loader={<span className="inline-block w-full h-full" />}
            />
          </div>
        </button>
      )}
    </div>
  );
}
