import React, { useCallback } from "react";
import { Img } from "react-image";
import { useSound } from "../../context/SoundContext";

const Sound: React.FC = () => {
  const { volume, setVolume } = useSound();

  const handleClick = useCallback(() => {
    setVolume(volume > 0 ? 0 : 1);
  }, [volume, setVolume]);

  return (
    <div className="relative">
      <Img
        src={
          volume > 0
            ? "/images/panel-header/sound-on.svg"
            : "/images/panel-header/sound-off.svg"
        }
        alt="sound"
        width={20}
        height={20}
        className="cursor-pointer"
        onClick={handleClick}
      />
    </div>
  );
};

export { Sound };
