import api from "../api";

interface WithdrawSkinResponse {
  success: boolean;
  status: string;
  message?: string;
  trade_url: string;
}

export async function withdrawSkin(
  userSkinId: number
): Promise<WithdrawSkinResponse> {
  const response = await api.request<WithdrawSkinResponse>(
    "user/withdrawSkins",
    {
      method: "POST",
      data: {
        user_skin_id: userSkinId,
      },
    }
  );

  return response;
}
