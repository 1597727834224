import React, { useState, useEffect, useRef } from "react";
import { SkinTypeSlider } from "../../../utils/types";
import SliderVertical from "./SliderVertical";
import { sellSkin } from "../../../api/skins/apiSellSkin";
import { useNotification } from "../../../context/NotificationContext";
import { useUserStore } from "../../../zustand/stores/userStore";
import { useTranslation } from "react-i18next";
import { useSound } from "../../../context/SoundContext";

interface MultiSliderBlockProps {
  count: number;
  skins: SkinTypeSlider[];
  initialSpins: (string | undefined)[];
  wonSkinIds: number[];
  wonSkinPrices: string[];
  onSpinEnd: () => void;
  isFastMode: boolean;
  handleTryAgain: () => void;
}

export default function MultiSliderBlock({
  count,
  skins,
  initialSpins,
  wonSkinIds,
  wonSkinPrices,
  onSpinEnd,
  isFastMode,
  handleTryAgain,
}: MultiSliderBlockProps) {
  const [soldSkins, setSoldSkins] = useState<Set<number>>(new Set());
  const { addNotification } = useNotification();
  const updateBalance = useUserStore((state) => state.updateBalance);
  const { t } = useTranslation();
  const { playWinSound } = useSound();
  const [winCardsShown, setWinCardsShown] = useState(0);
  const initialSpinsRef = useRef(initialSpins);
  const spinTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Сброс состояния при изменении initialSpins
    if (initialSpinsRef.current !== initialSpins) {
      initialSpinsRef.current = initialSpins;
      setWinCardsShown(0);
      setSoldSkins(new Set());

      // Очистка предыдущего таймаута, если он существует
      if (spinTimeoutRef.current) {
        clearTimeout(spinTimeoutRef.current);
      }

      // Запуск новой анимации
      spinTimeoutRef.current = setTimeout(() => {
        const event = new Event("startSpinning");
        window.dispatchEvent(event);
        playWinSound();
      }, 100);
    }

    // Очистка при размонтировании
    return () => {
      if (spinTimeoutRef.current) {
        clearTimeout(spinTimeoutRef.current);
      }
    };
  }, [initialSpins, playWinSound]);

  useEffect(() => {
    if (winCardsShown === count) {
      playWinSound();
      setWinCardsShown(0);
    }
  }, [winCardsShown, count, playWinSound]);

  const handleWinCardShow = () => {
    setWinCardsShown((prev) => prev + 1);
  };

  const handleSellSkin = async (skinId: number) => {
    try {
      const response = await sellSkin([skinId]);
      if (response.success) {
        updateBalance(response.new_balance);
        setSoldSkins((prevSoldSkins) => {
          const updatedSoldSkins = new Set(prevSoldSkins).add(skinId);
          if (updatedSoldSkins.size === wonSkinIds.length) {
            handleTryAgain();
          }
          return updatedSoldSkins;
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Error selling skin:", error);
        const errorMessage = error.message || "An unexpected error occurred";
        addNotification(errorMessage, "error");
      } else {
        console.error("Error selling skin:", error);
      }
    }
  };

  return (
    <div className="relative">
      <div className="relative w-full flex items-center justify-evenly bg-dark1 overflow-hidden rounded-20p">
        {[...Array(count)].map((_, index) => (
          <SliderVertical
            key={`${index}-${initialSpins[index]}`} // Добавлен initialSpin в ключ
            skins={skins}
            initialSpin={initialSpins[index]}
            wonSkinId={wonSkinIds[index]}
            wonSkinPrice={wonSkinPrices[index]}
            onSpinEnd={onSpinEnd}
            uniqueId={index}
            isFastMode={isFastMode}
            onSellSkin={handleSellSkin}
            isSold={(skinId) => soldSkins.has(skinId)}
            playWinSound={playWinSound}
            isFirstSlider={index === 0}
            onWinCardShow={handleWinCardShow}
          />
        ))}
      </div>
      <div className="arrow-left"></div>
      <div className="arrow-right"></div>
    </div>
  );
}
