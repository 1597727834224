import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Img } from "react-image";
import CaseBlocks from "./CaseBlocks";
import { SkinTypeSlider } from "../../utils/types";

interface CaseTopProps {
  caseName: string;
  skins: SkinTypeSlider[];
  name: string;
  caseImg: string;
  priceCase: number;
  currentCases: number;
  maxCases: number;
  missingBalance: number;
}

const CaseTop = ({
  caseName,
  skins,
  name,
  caseImg,
  priceCase,
  currentCases,
  maxCases,
  missingBalance,
}: CaseTopProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Используем useNavigate вместо useHistory

  const handleGoBack = () => {
    navigate(-1); // Переход на предыдущую страницу в истории
  };

  const percentage = (currentCases / maxCases) * 100;

  return (
    <>
      <div className="flex justify-between items-center gap-1 sm:gap-2.5 mb-7">
        <button
          className="flex justify-between items-center gap-1.5 sm:gap-2.5 px-2 py-2 sm:px-5 sm:py-3 border border-solid border-dark5 text-grey4 text-12.18 sm:text-14.24 font-bold rounded-30p"
          onClick={handleGoBack}
        >
          {/* <Img src="/images/cases/back.svg" alt="back" width={24} height={24} /> */}
          <div className="inline-block w-c18 h-c18 sm:w-6 sm:h-6">
            <Img
              src="/images/cases/back.svg"
              alt="back"
              className="object-cover w-full h-full"
              loader={<span className="inline-block w-full h-full" />}
            />
          </div>
          {t("back")}
        </button>
        <h2 className="w-32 sm:w-auto text-14 md:text-24.26 text-white font-medium text-center">
          {caseName}
        </h2>
        <div className="w-[92px] md:w-[120px]">
          {/* <div>
            <p className="text-10 sm:text-13 text-grey4 font-medium">
              {t("case-limit")}:{" "}
              <span className="text-yellow4 font-bold">
                {currentCases} / {maxCases}
              </span>
            </p>
          </div>
          <div className="w-full bg-progress-bar rounded-full h-1 mt-2 relative">
            <div
              className="bg-yellow-500 h-1 rounded-full"
              style={{ width: `${percentage}%` }}
            />
            <div className="absolute w-full text-center text-black top-0"></div>
          </div> */}
        </div>
      </div>
      <CaseBlocks
        skins={skins}
        name={name}
        priceCase={priceCase}
        caseImg={caseImg}
        missingBalance={missingBalance}
      />
    </>
  );
};

export default CaseTop;
