import { Img } from "react-image";
import { Link } from "react-router-dom";
import { SkinType } from "../../../utils/types";
import { getBgColorClass } from "../../../utils/rarityFonColor";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import truncateName from "../../../utils/truncateName";

interface CasesProps {
  casesItem: SkinType;
}

const UserCardProfile: React.FC<CasesProps> = ({ casesItem }) => {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const bgColorClass = getBgColorClass(casesItem.rarity);
  const { t } = useTranslation();

  const renderStatusIcon = () => {
    switch (casesItem.status) {
      case "SOLD":
        return (
          <Img
            src="/images/profile/sell-skin.svg"
            alt="sell"
            width={30}
            height={30}
            className="absolute top-3 left-3 opacity-80"
          />
        );
      case "EXCHANGE":
        return (
          <Img
            src="/images/profile/replace-skin.svg"
            alt="replace"
            width={30}
            height={30}
            className="absolute top-3 left-3 opacity-80"
          />
        );
      case "WITHDRAW":
        return (
          <Img
            src="/images/profile/withdraw-steam.svg"
            alt="take"
            width={30}
            height={30}
            className="absolute top-3 left-3 opacity-80"
          />
        );
      default:
        return null;
    }
  };

  const hasCaseInfo = casesItem.case_image && casesItem.name_id;

  const caseImageSrc =
    hasCaseInfo && casesItem.case_image
      ? casesItem.case_image
      : "/images/profile/exch.png";

  const renderCaseImage = () => (
    <Img
      src={caseImageSrc}
      alt={hasCaseInfo ? "case" : "default case"}
      className={`absolute left-0 transition-all duration-500 ease  w-[88px] h-[88px] md:w-[120px] md:h-[120px] ${
        hoveredItem === casesItem.id
          ? "top-12 md:top-14 opacity-1"
          : "top-44 opacity-0"
      }`}
    />
  );

  return (
    <div
      className={`relative aspect-[127/101.25] flex flex-col items-center justify-center rounded-14p pb-6 px-3 overflow-hidden ${bgColorClass}`}
      onMouseEnter={() => setHoveredItem(casesItem.id)}
      onMouseLeave={() => setHoveredItem(null)}
    >
      <span
        className={`absolute text-12 text-yellow4 font-semibold top-3 right-3 ${
          hoveredItem === casesItem.id && "opacity-20"
        }`}
      >
        {casesItem.symbol}
        {casesItem.price}
      </span>
      <Img
        src={casesItem.image_url}
        alt={casesItem.skin_name}
        className={`max-w-[70%] ${
          hoveredItem === casesItem.id && "opacity-20"
        }`}
      />
      <div
        className={`absolute left-3.5 bottom-3.5 ${
          hoveredItem === casesItem.id && "hidden md:hidden"
        }`}
      >
        <div className="flex gap-c3 mb-1">
          {casesItem.wear_en && (
            <span className="text-10.13 text-grey4 font-semibold border rounded-15p px-1.5 py-0.5">
              {casesItem.wear_en}
            </span>
          )}
          {casesItem.stattrak && (
            <span className="text-10.13 text-red9 font-semibold border rounded-15p px-1.5 py-0.5">
              ST
            </span>
          )}
        </div>
        <p className="text-12 text-cases-name font-medium">
          {casesItem.weapon}
        </p>
        <p className="text-13 text-cust-white font-medium">
          {truncateName(casesItem.skin_name, 18)}
        </p>
      </div>
      {hasCaseInfo ? (
        <>
          <Link to={`/case/${casesItem.name_id}`}>{renderCaseImage()}</Link>

          <p
            className={`absolute right-3.5 transition-all duration-500 ease text-12 text-cust-white font-medium max-w-[80px] text-right break-words overflow-hidden line-clamp-2" ${
              hoveredItem === casesItem.id
                ? "right-2 bottom-4 opacity-100"
                : "-bottom-8 opacity-0"
            }`}
          >
            {truncateName(casesItem.case_name, 20)}
          </p>
        </>
      ) : (
        <>
          {renderCaseImage()}

          <p
            className={`absolute right-3.5 transition-all duration-500 ease text-12 text-cust-white font-medium max-w-[80px] text-right break-words overflow-hidden line-clamp-2" ${
              hoveredItem === casesItem.id ? "right-2 bottom-4" : "-bottom-8"
            }`}
          >
            {t("exchange")}
          </p>
        </>
      )}

      {renderStatusIcon()}
    </div>
  );
};

export { UserCardProfile };
