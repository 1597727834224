import { Img } from "react-image";
import { SkinTypeSlider } from "../../../utils/types";
import { getBgColorClass } from "../../../utils/rarityFonColor";

interface CasesProps {
  casesItem: SkinTypeSlider;
}

const ItemCard: React.FC<CasesProps> = ({ casesItem }) => {
  // Получаем класс фона для текущей карточки
  const bgColorClass = getBgColorClass(casesItem.rarity);

  return (
    <div
      className={`relative aspect-[127/101.25] flex flex-col items-center justify-center rounded-14p pb-6 px-3 ${bgColorClass}`}
    >
      <Img
        src={casesItem.image_url}
        alt={casesItem.skin_name}
        width={160}
        height={116}
        className=" max-w-[70%] transition-transform transform-gpu hover:scale-110"
      />
      <div className="absolute left-3.5 bottom-3.5">
        <p className="text-12 text-cases-name font-medium">
          {casesItem.weapon}
        </p>
        <p className="text-13 text-cust-white font-medium">
          {casesItem.skin_name}
        </p>
      </div>
    </div>
  );
};

export { ItemCard };
