import api from "../api";
import { UserProfileInfo, UserSkinInfo } from "../../utils/types";

export const fetchUserInfo = (userId: number) => {
  return api.request<UserProfileInfo>("user/profile/userInfo", {
    method: "GET",
    params: { user_id: userId },
  });
};

export const fetchSkinInfo = (userId: number, page: number = 1) => {
  return api.request<UserSkinInfo>("user/profile/userInfoSkins", {
    method: "GET",
    params: { user_id: userId, page: page },
  });
};
