import React, { useState, useEffect, useCallback } from "react";
import { Img } from "react-image";
import { useTranslation } from "react-i18next";
import { UserCardProfile } from "./userCardProfile/UserCardProfile";
import SkeletonUserCardProfile from "./userCardProfile/SkeletonUserCardProfile";
import { SkinType } from "../../utils/types";
import { fetchSkinInfo } from "../../api/userProfile/fetchUserInfo";

interface UserItemsProfileProps {
  initialSkins: SkinType[];
  initialSkinCount: number;
  initialHasMore: boolean;
  userId: number;
}

const UserItemsProfile: React.FC<UserItemsProfileProps> = React.memo(
  ({ initialSkins, initialSkinCount, initialHasMore, userId }) => {
    const { t } = useTranslation();
    const [skins, setSkins] = useState<SkinType[]>(initialSkins);
    const [skinCount, setSkinCount] = useState<number>(initialSkinCount);
    const [hasMore, setHasMore] = useState<boolean>(initialHasMore);
    const [page, setPage] = useState<number>(1);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

    useEffect(() => {
      setSkins(initialSkins);
      setSkinCount(initialSkinCount);
      setHasMore(initialHasMore);
      setPage(1);
    }, [initialSkins, initialSkinCount, initialHasMore, userId]);

    const handleShowMore = useCallback(async () => {
      if (!hasMore || isLoadingMore) return;

      setIsLoadingMore(true);
      try {
        const nextPage = page + 1;
        const response = await fetchSkinInfo(userId, nextPage);
        if (response.success) {
          setSkins((prevSkins) => [...prevSkins, ...response.data]);
          setHasMore(response.hasMore);
          setPage(nextPage);
          setSkinCount(response.skin_count);
        }
      } catch (error) {
        console.error("Error when getting additional skins:", error);
      } finally {
        setIsLoadingMore(false);
      }
    }, [hasMore, isLoadingMore, page, userId]);

    return (
      <>
        <section className="flex gap-5 items-center space-x-4 mb-4">
          <div className="flex items-center">
            <div className="min-w-7 min-h-7 md:w-c34 md:h-c34 mr-2.5">
              <Img
                src="/images/profile/items.svg"
                alt="deposit-logo"
                className="object-contain w-full h-full"
                loader={<span className="w-full h-full mr-2.5" />}
              />
            </div>
            <p className="text-14 lg:text-23.28 text-white font-semibold">
              {t("user-items")} ({skinCount})
            </p>
          </div>
        </section>
        <section className="max-w-[1360px] w-full mx-auto">
          <div className="grid grid-cols-2 sm:grid-cols-5 lg:grid-cols-6 gap-1.5 sm:gap-3">
            {skins.map((item, index) => (
              <UserCardProfile key={`${item.id}-${index}`} casesItem={item} />
            ))}
          </div>
        </section>
        {isLoadingMore && (
          <div className="grid grid-cols-2 sm:grid-cols-5 lg:grid-cols-6 gap-1.5 sm:gap-3 mt-4">
            {Array(6)
              .fill(0)
              .map((_, index) => (
                <SkeletonUserCardProfile key={index} />
              ))}
          </div>
        )}
        {hasMore && !isLoadingMore && (
          <div className="mt-4 text-center">
            <button
              className="w-full md:w-auto py-2 px-4 border border-dark6 bg-transparent md:bg-dark4 text-15 text-grey5 font-semibold rounded-30p"
              onClick={handleShowMore}
            >
              {t("show-more")}
            </button>
          </div>
        )}
      </>
    );
  }
);

export default UserItemsProfile;
