import React, { useState } from "react";
import { useNotification } from "../context/NotificationContext";
import { useTranslation } from "react-i18next";

const Notification: React.FC = () => {
  const { notifications, removeNotification } = useNotification();
  const { t } = useTranslation();
  const [animating, setAnimating] = useState<number | null>(null); // Состояние для анимации

  // Обработка клика на уведомление
  const handleNotificationClick = (notificationId: number) => {
    handleRemoveNotification(notificationId); // Удаляем только конкретное уведомление
  };

  // Обработка нажатия на кнопку "accept trade"
  const handleTradeButtonClick = (
    e: React.MouseEvent,
    notificationId: number,
    tradeUrl?: string
  ) => {
    e.stopPropagation(); // Предотвращаем всплытие события клика
    if (tradeUrl) {
      window.open(tradeUrl, "_blank");
      handleRemoveNotification(notificationId); // Удаляем только конкретное уведомление
    }
  };

  // Удаление уведомления с анимацией
  const handleRemoveNotification = (id: number) => {
    setAnimating(id); // Устанавливаем ID для анимации
    setTimeout(() => {
      removeNotification(id); // Удаляем уведомление после 300ms
    }, 300); // Длительность анимации
  };

  return (
    <div className="fixed top-0.5 right-0.5 md:top-5 md:right-5 flex flex-col items-end z-50">
      {notifications.map((notification) => (
        <div
          key={notification.id} // Используем уникальный id как ключ
          className={`flex items-center cursor-pointer
                      ${
                        notification.image
                          ? "w-[300px] md:w-[360px] bg-[#1E1E1E] border-2 border-green-500 px-3"
                          : notification.type === "success"
                          ? "bg-green-500"
                          : "bg-red-500"
                      } 
                      p-1 rounded-lg text-white 
                      ${
                        notification.image ? "min-h-[115px]" : "max-w-xs"
                      } mt-1 shadow-md
                      transform transition-all duration-300 
                      ${
                        animating === notification.id
                          ? "translate-x-full opacity-0"
                          : "opacity-100"
                      }`} // Обновленный класс для анимации
          onClick={() => handleNotificationClick(notification.id)} // Передаем id уведомления
        >
          {notification.image ? (
            <>
              <img
                src={notification.image}
                alt="Notification"
                className="w-[45px] md:w-[90px] h-[45px] md:h-[90px] mr-2 md:mr-2 object-cover rounded"
              />
              <div className="flex flex-col justify-center flex-1 min-h-[90px]">
                <div className="text-13">
                  <span className="text-cases-name">
                    {t("item-ready-for-withdrawal")}
                  </span>{" "}
                  |
                  <span className="my-1 text-white flex-grow">
                    {notification.message}
                  </span>
                </div>
                {notification.tradeUrl && (
                  <button
                    onClick={
                      (e) =>
                        handleTradeButtonClick(
                          e,
                          notification.id,
                          notification.tradeUrl
                        ) // Передаем id уведомления и ссылку
                    }
                    className="bg-green3 hover:bg-green4 text-dark8 font-bold py-2 px-3 text-sm rounded cursor-pointer mt-1 w-full"
                  >
                    {t("accept-trade")}
                  </button>
                )}
              </div>
            </>
          ) : (
            <div>{notification.message}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Notification;
