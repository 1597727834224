import api from "../api";

interface SellSkinResponse {
  success: boolean;
  message?: string;
  total_skins_sold?: number;
  new_balance: number;
}

export async function sellSkin(
  skinId: number | number[] | "all"
): Promise<SellSkinResponse> {
  let skins: string | number[];

  if (skinId === "all") {
    skins = "all";
  } else if (Array.isArray(skinId)) {
    skins = skinId;
  } else {
    skins = [skinId];
  }

  return api.request<SellSkinResponse>(`user/sellSkins`, {
    method: "POST",
    data: { skins },
  });
}
