import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/app/app";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { NotificationProvider } from "./context/NotificationContext";
import { SoundProvider } from "./context/SoundContext";
import { socketService } from "./services/socketService";

// Инициализируем соединение socket.io
socketService.connect();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <SoundProvider>
      <NotificationProvider>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </NotificationProvider>
    </SoundProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
