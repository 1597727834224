import BlokBalance from "./blocks/BlockBalance";
import BlockAuthorized from "./blocks/BlockAuthorized";
import BlockLimit from "./blocks/BlockLimit";
import SliderBlock from "./blocks/SliderBlock";
import { useUserStore } from "../../zustand/stores/userStore";
import { SkinTypeSlider } from "../../utils/types";

interface CaseBlocksProps {
  skins: SkinTypeSlider[];
  name: string;
  priceCase: number;
  caseImg: string;
  missingBalance: number;
}

export default function CaseBlocks({
  skins,
  name,
  priceCase,
  caseImg,
  missingBalance,
}: CaseBlocksProps) {
  const isLoggedIn = useUserStore.use.isLoggedIn();
  const Limit: number = 10;
  const balance = useUserStore.use.getBalance()() ?? 0;

  return (
    <>
      {!isLoggedIn && <BlockAuthorized caseImg={caseImg} />}
      {isLoggedIn && Limit === 0 && <BlockLimit caseImg={caseImg} />}
      {isLoggedIn && Limit > 0 && balance >= priceCase && (
        <SliderBlock
          skins={skins}
          name={name}
          priceCase={priceCase}
          caseImg={caseImg}
        />
      )}
      {isLoggedIn && Limit > 0 && balance < priceCase && (
        <BlokBalance
          caseImg={caseImg}
          missingBalance={missingBalance !== undefined ? missingBalance : 0}
        />
      )}
    </>
  );
}
