import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import UserProfile from "../../../components/userProfile/UserProfile";
import UserItemsProfile from "../../../components/userProfile/UserItemsProfile";
import {
  fetchUserInfo,
  fetchSkinInfo,
} from "../../../api/userProfile/fetchUserInfo";
import { UserProfileInfo, UserSkinInfo } from "../../../utils/types";
import NotFound from "../../../components/NotFound";

export default function User() {
  const [userInfo, setUserInfo] = useState<UserProfileInfo | null>(null);
  const [skinInfo, setSkinInfo] = useState<UserSkinInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const getUserData = async () => {
      if (!id || !/^\d+$/.test(id)) {
        setNotFound(true);
        setLoading(false);
        return;
      }
      try {
        const userResponse = await fetchUserInfo(Number(id));
        const skinResponse = await fetchSkinInfo(Number(id));
        console.log(">ЮЗЕР:", userResponse);
        console.log("СКИН:", skinResponse);
        if (userResponse.success && skinResponse.success) {
          setUserInfo(userResponse);
          setSkinInfo(skinResponse);
        } else {
          setNotFound(true);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error("Error loading user data:", error);
          setError(error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    getUserData();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-500 my-16"></div>
      </div>
    );
  }

  if (notFound || !userInfo || !skinInfo || userInfo.username == null) {
    return <NotFound />;
  }

  return (
    <main className="flex flex-col w-full md:max-w-main md:mx-auto pt-4 md:pt-c30 pb-c26 md:pb-c66 main-mx">
      <UserProfile userInfo={userInfo} />
      <UserItemsProfile
        initialSkins={skinInfo.data}
        initialSkinCount={skinInfo.skin_count}
        initialHasMore={skinInfo.hasMore}
        userId={Number(id)}
      />
    </main>
  );
}
