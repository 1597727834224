import { useState, useMemo, ChangeEvent, useEffect } from "react";

export const usePaymentLogic = (
  initialMin: number,
  rate: number,
  isSameCurrency: boolean,
  isRubToUsd: boolean
) => {
  const roundToTwoDecimals = (num: number): number => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  const convertAmount = (amount: number, fromRubToUsd: boolean): number => {
    if (isSameCurrency) return amount;
    return fromRubToUsd ? amount / rate : amount * rate;
  };

  const [depositAmount, setDepositAmount] = useState<number | null>(
    roundToTwoDecimals(initialMin)
  );
  const [getUsdAmount, setGetUsdAmount] = useState<number | null>(
    roundToTwoDecimals(convertAmount(initialMin, isRubToUsd))
  );

  const [giftCode, setGiftCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    setDepositAmount(roundToTwoDecimals(initialMin));
    setGetUsdAmount(roundToTwoDecimals(convertAmount(initialMin, isRubToUsd)));
  }, [initialMin, rate, isSameCurrency, isRubToUsd]);

  const handleDepositChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === "" ? null : Number(event.target.value);
    setDepositAmount(value);
    setGetUsdAmount(
      value === null
        ? null
        : roundToTwoDecimals(convertAmount(value, isRubToUsd))
    );
  };

  const handleGetUsdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === "" ? null : Number(event.target.value);
    setGetUsdAmount(value);
    setDepositAmount(
      value === null
        ? null
        : roundToTwoDecimals(convertAmount(value, !isRubToUsd))
    );
  };

  const handleGiftCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGiftCode(event.target.value);
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const isDepositBelowMin =
    depositAmount === null || depositAmount < initialMin;
  const isUsdBelowMin =
    getUsdAmount === null ||
    getUsdAmount < roundToTwoDecimals(convertAmount(initialMin, isRubToUsd));

  const validGiftCodes = useMemo(
    () => ["1", "3", "5", "10", "20", "30", "50", "100"],
    []
  );

  const isGiftCodeValid = useMemo(
    () => validGiftCodes.includes(giftCode),
    [giftCode, validGiftCodes]
  );

  const isGiftCardButtonActive = isGiftCodeValid;
  const isWalletsButtonActive = phoneNumber.trim() !== "";

  return {
    depositAmount,
    getUsdAmount,
    giftCode,
    phoneNumber,
    handleDepositChange,
    handleGetUsdChange,
    handleGiftCodeChange,
    handlePhoneNumberChange,
    isGiftCardButtonActive,
    isWalletsButtonActive,
    isDepositBelowMin,
    isUsdBelowMin,
  };
};
