import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import GiftCardPay from "./PaymentMethods/GiftCardPay";
import CryptoPay from "./PaymentMethods/CryptoPay";
import WalletsPay from "./PaymentMethods/WalletsPay";
import { PaymentFormProps } from "./types";
import { useUserStore } from "../../zustand/stores/userStore";

// Компонент формы оплаты, принимающий различные пропсы для управления платежом
const PaymentForm: React.FC<PaymentFormProps> = ({
  activeButton,
  depositAmount,
  getUsdAmount,
  giftCode,
  phoneNumber,
  handleDepositChange,
  handleGetUsdChange,
  handleGiftCodeChange,
  handlePhoneNumberChange,
  selectedPayItem,
  isSameCurrency,
  isDepositBelowMin,
  isUsdBelowMin,
}) => {
  // Получение символа валюты пользователя из хранилища
  const symbol = useUserStore.use.getSymbol()();
  // Хук для интернационализации
  const { t } = useTranslation();

  // Функция для рендеринга компонента оплаты в зависимости от выбранного метода
  const renderPayComponent = () => {
    switch (activeButton) {
      case "Wallets":
        return (
          <WalletsPay
            phoneNumber={phoneNumber}
            onPhoneNumberChange={handlePhoneNumberChange}
          />
        );
      case "Crypto":
        if (selectedPayItem && selectedPayItem.networks) {
          return (
            <CryptoPay
              selectedToken={selectedPayItem}
              depositAmount={depositAmount === null ? null : depositAmount}
              minDepositAmount={selectedPayItem.min}
            />
          );
        }
        return null;
      case "Gift Card":
        return (
          <GiftCardPay
            giftCode={giftCode}
            onGiftCodeChange={handleGiftCodeChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col w-full gap-y-4 items-start bg-dark4 rounded-14p p-c18 mb-2.5 md:mb-0 mt-2.5 order-1 md:order-3">
      {activeButton !== "Gift Card" && (
        <div className="flex flex-col justify-between gap-c7">
          <p className="text-12 mb-1 ml-3">{t("enter-the-amount")}</p>
          <div className="flex justify-between items-center gap-c7">
            {/* Поле ввода суммы депозита */}
            <div className="flex flex-col gap-1.5 items-start w-full">
              <div className="flex gap-1.5 items-center w-full py-2 px-4 md:py-c13 text-13.18 text-label-text bg-label1 rounded-30p">
                <div className="flex-shrink-0 w-5 h-5 sm:w-c26 sm:h-c26">
                  <Img
                    src="/images/deposit/swap-left.svg"
                    alt="swap-left"
                    className="object-contain w-full h-full"
                    loader={<span className="inline-block w-full h-full" />}
                  />
                </div>
                <div className="flex flex-col">
                  <span className="text-11.12 text-grey3">
                    {selectedPayItem?.currency_symbol}
                  </span>
                  <input
                    type="number"
                    value={depositAmount === null ? "" : depositAmount}
                    onChange={handleDepositChange}
                    placeholder="0"
                    className="w-full bg-label1 focus:outline-none text-12 md:text-15.20 text-white [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </div>
              </div>
            </div>
            {/* Иконка обмена */}
            <div className="flex-shrink-0 w-c22 h-c22 sm:w-c30 sm:h-c30">
              <Img
                src="/images/deposit/exchange.svg"
                alt="exchange"
                className="object-contain w-full h-full"
                loader={<span className="inline-block w-full h-full" />}
              />
            </div>
            {/* Поле ввода суммы в USD (или другой валюте пользователя) */}
            <div className="flex flex-col gap-1.5 items-start w-full">
              <div className="flex gap-1.5 w-full py-2 px-4 md:py-c13 text-13.18 items-center text-label-text bg-label1 rounded-30p">
                <div className="flex-shrink-0 w-5 h-5 sm:w-c26 sm:h-c26">
                  <Img
                    src={
                      symbol === "₽"
                        ? "/images/deposit/swap-right-r.svg"
                        : "/images/deposit/swap-right.svg"
                    }
                    alt="swap-right"
                    className="object-contain w-full h-full"
                    loader={<span className="inline-block w-full h-full" />}
                  />
                </div>
                <div className="flex flex-col">
                  <span className="text-11.12 text-grey3">{symbol}</span>
                  <input
                    type="number"
                    value={
                      isSameCurrency
                        ? depositAmount === null
                          ? ""
                          : depositAmount
                        : getUsdAmount === null
                        ? ""
                        : getUsdAmount
                    }
                    onChange={handleGetUsdChange}
                    placeholder="0"
                    className="w-full bg-label1 focus:outline-none text-12 md:text-15.20 text-white [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Рендеринг компонента оплаты в зависимости от выбранного метода */}
      {renderPayComponent()}
      {/* Отображение минимальной суммы депозита */}
      <div className="w-full bg-c-grey2 py-2.5 px-4 rounded-19p border border-dotted border-c-grey3">
        <p
          className={`text-13 ${
            isDepositBelowMin || isUsdBelowMin ? "text-red-500" : "text-white"
          }`}
        >
          {t("minimum-amount")}{" "}
          <span className="text-white">
            {selectedPayItem?.min} {selectedPayItem?.currency}
          </span>
        </p>
      </div>
    </div>
  );
};

export default PaymentForm;
