// Импортируем необходимые зависимости из React и других модулей
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next"; // Для интернационализации
import { SkinTypeSlider } from "../../../utils/types"; // Импорт пользовательского типа
import { getSliderVerticalItemfon } from "../../../utils/rarityFonColor"; // Функция для получения фона элемента слайдера
import { Img } from "react-image"; // Компонент для отображения изображений
import { useUserStore } from "../../../zustand/stores/userStore";

// Определяем интерфейс для пропсов компонента SliderVertical
interface SliderVerticalProps {
  skins: SkinTypeSlider[]; // Массив скинов для отображения в слайдере
  initialSpin?: string; // Начальный спин (если есть)
  wonSkinId: number;
  wonSkinPrice: string; // цены выигрышных скинов
  onSpinEnd: () => void; // Функция, вызываемая при окончании вращения
  uniqueId: number; // Уникальный идентификатор слайдера
  isFastMode: boolean; // Флаг быстрого режима
  onSellSkin: (skinId: number) => Promise<void>; // Функция для продажи скина
  isSold: (skinId: number) => boolean; // Функция для проверки, продан ли скин
  playWinSound: () => void; // Проп для воспроизведения звука
  isFirstSlider: boolean; // Проп для определения, является ли это первым слайдером
  onWinCardShow: () => void; // Проп для уведомления о показе выигрышной карточки
}

// Определяем компонент SliderVertical как функциональный компонент React
const SliderVertical: React.FC<SliderVerticalProps> = ({
  skins,
  initialSpin,
  wonSkinId,
  wonSkinPrice,
  onSpinEnd,
  uniqueId,
  isFastMode,
  onSellSkin,
  isSold,
  playWinSound,
  isFirstSlider,
  onWinCardShow,
}) => {
  const symbol = useUserStore.use.getSymbol()();
  // Создаем реф для доступа к DOM-элементу колеса
  const wheelRef = useRef<HTMLDivElement>(null);
  // Используем хук useTranslation для интернационализации
  const { t } = useTranslation();
  // Состояния компонента
  const [spinning, setSpinning] = useState(false); // Вращается ли слайдер
  const [winningCardData, setWinningCardData] = useState<SkinTypeSlider | null>(
    null
  ); // Данные выигрышной карты
  const [showWinningCard, setShowWinningCard] = useState(false); // Показывать ли выигрышную карту
  const animationRef = useRef<Animation | null>(null); // Реф для анимации вращения
  const centeringAnimationRef = useRef<Animation | null>(null); // Реф для анимации центрирования
  const [animationComplete, setAnimationComplete] = useState(false); // Завершена ли анимация

  // Константы для анимации
  const ANIMATION_DURATION = 6000; // Длительность основной анимации в миллисекундах
  const CENTERING_DURATION = 500; // Длительность анимации центрирования в миллисекундах
  const cardHeight = 140; // Высота карточки в пикселях

  // Эффект для инициализации начального спина
  useEffect(() => {
    if (initialSpin !== undefined) {
      if (isFastMode) {
        // В быстром режиме сразу показываем выигрышную карту
        const winningCard = skins.find((skin) => skin.id === initialSpin);
        if (winningCard) {
          setWinningCardData({
            ...winningCard,
            price: wonSkinPrice.toString(), // Используем цену из пропсов
          });
          setShowWinningCard(true);
          setAnimationComplete(true);
          onSpinEnd();
          onWinCardShow(); // Уведомляем о показе выигрышной карточки
        }
      } else {
        if (isFirstSlider) {
          playWinSound(); // Воспроизводим звук только для первого слайдера
        }
        // В обычном режиме запускаем анимацию вращения
        spinWheelToId(initialSpin);
      }
    }
  }, [initialSpin, isFastMode, skins, onSpinEnd, isFirstSlider, playWinSound]);

  // Эффект для обработки изменения видимости страницы
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (animationRef.current && !animationComplete) {
        if (document.hidden) {
          // Если страница скрыта, приостанавливаем анимацию
          animationRef.current.pause();
          if (centeringAnimationRef.current) {
            centeringAnimationRef.current.pause();
          }
        } else {
          // Если страница снова видима, возобновляем анимацию
          animationRef.current.play();
          if (centeringAnimationRef.current) {
            centeringAnimationRef.current.play();
          }
        }
      }
    };

    // Добавляем слушатель события изменения видимости
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // Удаляем слушатель при размонтировании компонента
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [animationComplete]);

  // Функция для запуска вращения колеса к определенному ID
  const spinWheelToId = (id: string) => {
    if (!wheelRef.current) return;
    setSpinning(true);
    setShowWinningCard(false);
    setAnimationComplete(false);

    const wheel = wheelRef.current;
    const targetIndex = skins.findIndex((skin) => skin.id === id);
    if (targetIndex === -1) return;

    const columns = 12;
    const initialOffset = cardHeight / 2;

    // Вычисляем позицию для остановки колеса
    let landingPosition =
      (columns * skins.length + targetIndex) * cardHeight - initialOffset;
    const randomize = Math.floor(Math.random() * cardHeight) - cardHeight / 2;
    landingPosition += randomize;

    // Определяем ключевые кадры анимации
    const keyframes = [
      { transform: "translateY(0)" },
      { transform: `translateY(-${landingPosition}px)` },
    ];

    // Настройки времени анимации
    const timing = {
      duration: ANIMATION_DURATION,
      easing: "cubic-bezier(.1,.7,.5,1)",
    };

    // Отменяем предыдущую анимацию, если она есть
    if (animationRef.current) {
      animationRef.current.cancel();
    }

    // Запускаем новую анимацию
    animationRef.current = wheel.animate(keyframes, timing);

    // Обработчик завершения анимации
    animationRef.current.onfinish = () => {
      wheel.style.transform = `translateY(-${landingPosition}px)`;

      // Анимация центрирования
      const centeringDistance = randomize;
      const centeringKeyframes = [
        { transform: `translateY(-${landingPosition}px)` },
        { transform: `translateY(-${landingPosition - centeringDistance}px)` },
      ];

      const centeringTiming = {
        duration: CENTERING_DURATION,
        easing: "ease-out",
      };

      centeringAnimationRef.current = wheel.animate(
        centeringKeyframes,
        centeringTiming
      );

      // Обработчик завершения анимации центрирования
      centeringAnimationRef.current.onfinish = () => {
        setSpinning(false);
        wheel.style.transform = `translateY(-${
          landingPosition - centeringDistance
        }px)`;
        const winningCard = skins[targetIndex];
        setWinningCardData({
          ...winningCard,
          price: wonSkinPrice,
        });
        setShowWinningCard(true);
        setAnimationComplete(true);
        onSpinEnd();
        onWinCardShow();
      };
    };
  };

  // Функция для рендеринга скинов
  const renderSkins = () => {
    const repeatedSkins = [...Array(skins.length * 2 + 30)].flatMap(
      () => skins
    );

    return repeatedSkins.map((skin, index) => (
      <div
        key={`${skin.id}-${index}`}
        data-id={skin.id}
        className={`card-vert relative ${!spinning ? "dimmed-card" : ""}`}
      >
        {getSliderVerticalItemfon(skin.rarity)}
        <img className="img-skin" src={skin.image_url} alt={skin.skin_name} />
      </div>
    ));
  };

  // Обработчик клика на кнопку продажи
  const handleSellClick = () => {
    if (winningCardData) {
      onSellSkin(wonSkinId);
    }
  };

  // Рендерим компонент
  return (
    <div className={`slider-container ${spinning ? "spinning" : ""}`}>
      {!showWinningCard && (
        <div
          className={`roulette-wrapper relative flex flex-col justify-center w-full m-0 overflow-hidden rounded-20p ${
            isFastMode ? "opacity-50" : ""
          }`}
        >
          <div
            className="selector h-[2px] bg-red8 top-1/2 w-[90%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-10"
            style={{ display: spinning ? "block" : "none" }}
          ></div>
          <div ref={wheelRef} className="wheel flex flex-col">
            {renderSkins()}
          </div>
        </div>
      )}
      {showWinningCard && winningCardData && (
        <div
          className={`win-block-vert win-block-vert-${uniqueId} ${
            showWinningCard ? "show px-0.5 md:px-1" : ""
          } flex flex-col justify-center items-center h-full`}
        >
          <div className="flex justify-center w-full">
            {getSliderVerticalItemfon(winningCardData.rarity)}
          </div>
          <img
            className="img-win z-10"
            src={winningCardData.image_url}
            alt={winningCardData.skin_name}
          />
          <p className="text-9 md:text-13 text-center text-cases-name font-medium mb-1">
            {winningCardData.weapon}
          </p>
          <p className="flex items-center justify-center min-h-12 md:min-h-c52 lg:min-h-c34 text-9 md:text-14 text-center text-cust-white font-medium mb-1 md:mb-2">
            {winningCardData.skin_name}
          </p>
          <p className="text-10 md:text-13 text-center text-yellow4 font-semibold mb-2">
            {symbol}
            {winningCardData.price}
          </p>
          <button
            className={`sell-button px-3 md:px-c25 py-1.5 md:py-2 bg-green3 hover:bg-green4 text-dark8 text-13 font-bold rounded-30p ${
              isSold(wonSkinId) ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleSellClick}
            disabled={isSold(wonSkinId)}
          >
            <span className="hidden md:block">{t("sell")}</span>
            <span className="md:hidden">
              <Img
                src="/images/profile/cart.svg"
                alt="cart"
                width={18}
                height={18}
                loader={
                  <span
                    style={{
                      width: 18,
                      height: 18,
                    }}
                  />
                }
              />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

// Экспортируем компонент по умолчанию
export default SliderVertical;
